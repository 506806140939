import React from "react";
import Layout from "../components/Layout";

const contact = props => {
  return (
    <Layout>
      <section className="contact-page">
        <article className="contact-form">
          <h3>Get in touch</h3>
          <form action="https://formspree.io/f/xwkwjpqb" method="POST">
            <div className="form-group">
              <input type="text" placeholder="name" name="name" className="form-control" />
              <input type="email" name="email" placeholder="email" className="form-control" />
              <textarea name="message" id="" rows="10" placeholder="message" className="form-control"></textarea>
              <input type="hidden" name="error" value={props?.location?.state?.error} />
            </div>
            <button type="submit" className="submit-btn btn">
              submit here
            </button>
          </form>
        </article>
      </section>
    </Layout>
  );
};

export default contact;
